import React from "react"
import loreIcon from '../img/lore.png'
import commonIcon from '../img/common.png'
import uncommonIcon from '../img/uncommon.png'
import rareIcon from '../img/rare.png'
import legendaryIcon from '../img/legendary.png'
import exoticIcon from '../img/exotic.png'
import warlockIcon from '../img/warlockIcon.svg'
import hunterIcon from '../img/hunterIcon.svg'
import titanIcon from '../img/titanIcon.svg'

let rarityURL = <img src={loreIcon} alt="Lore" className="rarity-icon" />;
let classURL = '';

function iconCheck(post) {
  if (post.gearRarity) {
    if (post.gearRarity.includes("exotic")) {
      return <img src={exoticIcon} alt="Exotic" className="rarity-icon" />
    } else if (post.gearRarity.includes("legendary")) {
      return <img src={legendaryIcon} alt="Legendary" className="rarity-icon" />
    } else if (post.gearRarity.includes("rare")) {
      return <img src={rareIcon} alt="Rare" className="rarity-icon" />
    } else if (post.gearRarity.includes("uncommon")) {
      return <img src={uncommonIcon} alt="Uncommon" className="rarity-icon" />
    } else {
      return <img src={commonIcon} alt="Common" className="rarity-icon" />
    }
  }
};

function classCheck(post) {
  if (post.gearClass) {
    if (post.gearClass.includes("warlock")) {
      return <img src={warlockIcon} alt="Warlock" className="rarity-icon" />
    } else if (post.gearClass.includes("hunter")) {
      return <img src={hunterIcon} alt="Hunter" className="rarity-icon" />
    } else if (post.gearClass.includes("titan")) {
      return <img src={titanIcon} alt="Titan" className="rarity-icon" />
    } else {
      return ''
    }
  }
}

const GearLink = ({ post }) => (

  <div className="post-single post-gear">
    <h3>{post.title}</h3>
    <p className="hand-written" >{post.gearType && post.gearType.title}</p>
    <div
      className="blog-post-content"
      dangerouslySetInnerHTML={{ __html: post.HTML.outerHTML }}
    />
    <div className="gear-icons">
      {iconCheck(post)}
      {classCheck(post)}
    </div>
  </div>
  
)

export default GearLink